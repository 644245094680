import { SetStateAction, useEffect, useState, useCallback } from 'react'
import { useQuery } from '@tanstack/react-query'
import { Autocomplete } from '@enterprise-ui/canvas-ui-react-autocomplete'

import {
  getItemTypeTaxonomies,
  ItemTypeTaxonomies,
} from 'services/getItemTypeTaxonomies'

interface OwnProps {
  subTypeId: string
  slectedItemTypeId: string[]
  setAllowedProductSubtypes: Function
  index: number
  isPreSelcted: boolean
}

const AllowedItemtype = ({
  index,
  isPreSelcted,
  subTypeId,
  slectedItemTypeId,
  setAllowedProductSubtypes,
}: OwnProps) => {
  const [autocompleteValue, setAutocompleteValue] = useState<
    ItemTypeTaxonomies[]
  >([])

  const { data: itemTypeOptions, isLoading } = useQuery(
    ['GET_ITEM_TYPE_TAXNOMIES', subTypeId],
    () =>
      getItemTypeTaxonomies({
        status: 'ACTIVE',
        id: subTypeId,
        depth: 3,
        target: 'EXTERNAL',
        perPage: 200,
      }),
    {
      enabled: !!subTypeId,
    },
  )

  const updateSlectedItemTypes = useCallback(
    (selectedItemTypes: any) => {
      setAllowedProductSubtypes((prevState: any[]) =>
        prevState.map((data) => {
          if (subTypeId === data?.id) {
            return {
              ...data,
              selectedItemTypes,
            }
          }
          return data
        }),
      )
    },
    [setAllowedProductSubtypes, subTypeId],
  )

  useEffect(() => {
    if (itemTypeOptions?.length) {
      if (subTypeId && slectedItemTypeId?.length === 0) {
        if (!isPreSelcted) {
          setAutocompleteValue(itemTypeOptions)
        }
        updateSlectedItemTypes(itemTypeOptions)
      } else {
        const selectedItemTypeOptions = itemTypeOptions?.filter(
          (itemTypeOption: ItemTypeTaxonomies) => {
            return slectedItemTypeId?.includes(itemTypeOption.id)
          },
        )
        setAutocompleteValue(selectedItemTypeOptions)
        updateSlectedItemTypes(selectedItemTypeOptions)
      }
    }

    return () => setAutocompleteValue([])
  }, [
    itemTypeOptions,
    slectedItemTypeId,
    subTypeId,
    updateSlectedItemTypes,
    isPreSelcted,
  ])

  const onAutoCompleteUpdate = (
    _id: any,
    value: SetStateAction<ItemTypeTaxonomies[]>,
  ) => {
    setAutocompleteValue(value)
    updateSlectedItemTypes(value)
  }

  if (isLoading) {
    return null
  }

  return (
    <Autocomplete
      multiselect
      allowSelectAll
      label={index === 0 ? 'Item Types *' : ''}
      options={itemTypeOptions}
      value={autocompleteValue}
      onUpdate={onAutoCompleteUpdate}
    />
  )
}

export default AllowedItemtype
